import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ReferralHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const uniqueLink = location.search.substring(1); // Removes the '?' at the start

    if (uniqueLink) {
      validateReferralLink(uniqueLink).then(isValid => {
        if (isValid) {
          console.log('Referral link is valid');
          // Here you can also update the user's data or perform any other actions required upon successful referral validation
        } else {
          console.error('Referral link is invalid');
        }
        navigate('/'); // Redirect to home or another appropriate page after processing
      }).catch(error => {
        console.error('Error validating referral link:', error);
        navigate('/'); // Redirect to a fallback page in case of error
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      console.error('Referral link is missing');
      navigate('/'); // Redirect to a fallback page if 'link' parameter is missing
    }
  }, [location, navigate]);

  const validateReferralLink = async (uniqueLink) => {
    console.log('Validating referral link...')
    // console.log(uniqueLink)
    try {
      // you need this in development otherwise 403 error
      // const response = await fetch(`http://localhost:5002/api/code?${uniqueLink}`);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/code?link=${uniqueLink}`);
      if (!response.ok) {
        console.error('Error fetching referral validation:', response.status, response.statusText);
        return false;
      }
      const data = await response.json();
      if (data.success) {
        localStorage.setItem('inviteLink', uniqueLink);
        localStorage.setItem('Invitee', JSON.stringify(data.invitee));
        console.log(data.message); // "Referral link processed successfully."
        //update link here when showing compatibility index
        navigate('/'); // Redirect to home or another appropriate page
        return true;
      } 
  } catch (error) {
    console.error('Error fetching referral validation:', error);
    return false;
//        throw error;
  }
};


  if (isLoading) {
    return <div>Loading...</div>; // Render a loading indicator while processing
  }

  return null; // Render nothing or a confirmation message as needed
};

export default ReferralHandler;