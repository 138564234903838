// FirstNameForm.js
import React, { useState } from 'react';

function FirstNameForm({ formData, handleChange, nextStep }) {
  const firstName = formData ? formData.firstName : '';
  const lastName = formData ? formData.lastName : ''; 

  const handleFirstNameChange = (e) => {
    handleChange('firstName', e.target.value); //updates the value in form every time it senses a change in value in the box
  };

  const handleLastNameChange = (e) => {
    handleChange('lastName', e.target.value);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("Form submitted");
  //   nextStep();
  // };

  // const saveNames = () => {
  //   nextStep();
  // };

  // const saveFirstName = () => {
  //   // Send the first name to the server
  //   fetch(`${process.env.REACT_APP_BASE_URL}/user/first-name`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ firstName }),
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log('Server response:', data);
  //       // Handle the server response as needed
  //     })
  //     .catch((error) => {
  //       console.error('Error saving first name:', error);
  //     });
  // };

  return (
    <div className="name-container fade-in2">
    <h2 style={{ marginBottom: 0 }}>Hey, what's your name?</h2>
    <label>First Name*</label>
      <input
        type="text"
        value={firstName}
        onChange={handleFirstNameChange}
        onKeyDown={(e) => {
        if (e.key === "Enter")
            nextStep();
        }}
        // placeholder="Enter your first name"
      />
      <label>Last Name*</label>
      <input
        type="text"
        value={lastName}
        onChange={handleLastNameChange}
        onKeyDown={(e) => {
        if (e.key === "Enter")
            nextStep();
        }}
        // placeholder="Enter your last name"
      />
      {/* <input type="submit" value="Next" /> */}
      {/* <button onClick={saveNames}>Save</button> */}
      
    </div>
  );
}

export default FirstNameForm;