import React, {useState} from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase-config';
import '../styles/Navbar.css'
import logo from './logo.png';
import newLogo from './Flair-logo2.png';

const Navbar = ({ user }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful
        localStorage.removeItem('inviteLink');
        localStorage.removeItem('userID');
        localStorage.removeItem('Invitee');
        localStorage.removeItem('myLink');
        localStorage.removeItem('tags')
        localStorage.removeItem('user');
        localStorage.removeItem('connections');
        window.location.href = '/'; // navigate to home page after logout
      })
      .catch((error) => {
        // An error happened
        console.error(error);
      });
  };


  const handleProfile = () => {
    // navigate to profile
    window.location.href = '/profile';
  };

  const handleBoard = () => {
    // navigate to profile
    window.location.href = '/leaderboard';
  };


  //   return (
  //     <div className="navbar">
  //       {user ? (
  //         <>
  //           <p>Hello, {user.displayName}!</p>
  //           <button onClick={handleLogout}>Logout</button>
  //         </>
  //       ) : (
  //         <p>Please sign in</p>
  //       )}
  //     </div>
  //   );
  return (
    <nav className='header'>
      <div className='spacer'></div>
      <a href="/" style={{display: "flex", alignItems: "center"}}>
        <img src={logo} alt="Logo" className="navbar-logo fade-in" />
        <img src={newLogo} alt="Logo-2" className="navbar-logo fade-in" id="text" />
      </a>
      <div className='navbar fade-in'>
        {/* {user ? (
          <div style={{ textAlign: 'center' }}>
            <p>Hello, {user.displayName}!</p>
            <button onClick={handleLogout} style={{ cursor: 'pointer', marginTop: '5px' }}>Logout</button>
          </div>
        ) : null} */}
        {user && user.photoURL && (
          <div>
            <button id="profile" onClick={() => setDropdownOpen(!dropdownOpen)} style={{ background: "none", border: "none" }}>
              <img src={user.photoURL} alt="User" />
            </button>
            {dropdownOpen && (
              <div className="dropdown">
                <button id="dd" onClick={handleProfile}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={'20px'}>
                  <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
                  My Profile
                </button>
                <button id="dd" onClick={handleBoard}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={"20px"}>
                  <path d="M4.1 38.2C1.4 34.2 0 29.4 0 24.6C0 11 11 0 24.6 0H133.9c11.2 0 21.7 5.9 27.4 15.5l68.5 114.1c-48.2 6.1-91.3 28.6-123.4 61.9L4.1 38.2zm503.7 0L405.6 191.5c-32.1-33.3-75.2-55.8-123.4-61.9L350.7 15.5C356.5 5.9 366.9 0 378.1 0H487.4C501 0 512 11 512 24.6c0 4.8-1.4 9.6-4.1 13.6zM80 336a176 176 0 1 1 352 0A176 176 0 1 1 80 336zm184.4-94.9c-3.4-7-13.3-7-16.8 0l-22.4 45.4c-1.4 2.8-4 4.7-7 5.1L168 298.9c-7.7 1.1-10.7 10.5-5.2 16l36.3 35.4c2.2 2.2 3.2 5.2 2.7 8.3l-8.6 49.9c-1.3 7.6 6.7 13.5 13.6 9.9l44.8-23.6c2.7-1.4 6-1.4 8.7 0l44.8 23.6c6.9 3.6 14.9-2.2 13.6-9.9l-8.6-49.9c-.5-3 .5-6.1 2.7-8.3l36.3-35.4c5.6-5.4 2.5-14.8-5.2-16l-50.1-7.3c-3-.4-5.7-2.4-7-5.1l-22.4-45.4z" /></svg>
                  Leaderboard</button>
                {/* <button id="dd" onClick={handleBoard}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={"30px"}>
                <path d="M353.8 54.1L330.2 6.3c-3.9-8.3-16.1-8.6-20.4 0L286.2 54.1l-52.3 7.5c-9.3 1.4-13.3 12.9-6.4 19.8l38 37-9 52.1c-1.4 9.3 8.2 16.5 16.8 12.2l46.9-24.8 46.6 24.4c8.6 4.3 18.3-2.9 16.8-12.2l-9-52.1 38-36.6c6.8-6.8 2.9-18.3-6.4-19.8l-52.3-7.5zM256 256c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V288c0-17.7-14.3-32-32-32H256zM32 320c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H160c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zm416 96v64c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32V416c0-17.7-14.3-32-32-32H480c-17.7 0-32 14.3-32 32z"/></svg>
                  Leaderboard</button> */}
                  <button id="dd" onClick={handleLogout}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={'20px'}>
                    <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" /></svg>
                  Sign Out</button>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
}
export default Navbar;