import React, { useEffect, useState } from "react";
import { auth } from "../config/firebase-config";
import { Link } from "react-router-dom";

const SuccessPage = () => {
  const [user, setUser] = useState(null);
  const [profileStatus, setProfileStatus] = useState(true);
  const [socialStatus, setSocialStatus] = useState(true);

  useEffect(() => {
    // Set an observer on the Auth object to get the current user
    // const unsubscribe = auth.onAuthStateChanged((currentUser) => {
    //   setUser(currentUser);

    //   // If the user data hasn't been loaded yet, or if neither YouTube nor Spotify are set, return null
    //   if (!currentUser) {
    //     alert("Please sign in to continue");
    //     window.location.href = `/`;
    //   }

      
    //   // this won't work because i cannot the information from backend (regarding the existence of spotify and youtube in the user profile)
    //   // else if (!currentUser.youtube && !currentUser.spotify) {
    //   //   alert("Please connect your socials first");
    //   //   window.location.href = `/connect`;
    //   // }
    //   // console.log('Current User:', currentUser);
    // });

    const fetchData = async () => {
      try {
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
          setUser(currentUser);
          // console.log('Current User:', currentUser);

          if (currentUser) {
            const uid = currentUser.uid;
            // console.log('Calling backend');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/get-user?uid=${uid}`);
            const data = await response.json();
            setUser(data.userDoc);
            if (!data.userDoc.user) {
              setProfileStatus(false);
              localStorage.setItem(`profile`, "false");
            }
            if ((!data.userDoc.spotify || data.userDoc.spotify.length === 0) || (!data.userDoc.youtube || data.userDoc.youtube.length === 0)) {
              setSocialStatus(false);
              localStorage.setItem(`social`, "false");
            }
          } else {
            console.error('User is not logged in');
            window.location.href = `/`;
          }
        });

        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching data from server', error);
        
      }
    };

    fetchData();

    // Cleanup the observer when the component is unmounted
    // return () => unsubscribe();
  }, []);

  return (
    <div className="gradient-background-2">
      <h2 className="fade-in2">Congratulations! 🥳</h2>
      <p className="fade-in2">You have successfully connected your Socials</p>
      <br></br>
      <p className="fade-in2">
        You will be notified when we find a match for you via email/text
        message!👀
      </p>
      <br></br>
      <button className="register-btn fade-in" onClick={() => window.location.href='/leaderboard'}>
        Check Leaderboard
      </button>
      {!profileStatus &&
      <button className="register-btn fade-in" onClick={() => window.location.href='/register'}>
        Complete Your Profile
      </button>
      }
      {!socialStatus &&
      <button className="register-btn fade-in" onClick={() => window.location.href='/connect'}>
        Connect Your Socials
      </button>
      }
    </div>
  );
};

export default SuccessPage;
