// LoginForm.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  onAuthStateChanged,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { app, auth, googleProvider } from "../config/firebase-config";
import "../styles/LoginForm.css"; // import the css file
import UserContext from "../config/UserContext";
import Background from '../components/stacked-waves.svg'

function LoginForm() {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const inviteLink = localStorage.getItem("inviteLink");
  const inviteeString = localStorage.getItem('Invitee'); //localstorage can only store strings. So, you need to parse it once you get it
  const invitee = JSON.parse(inviteeString);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);

      if(inviteLink){
        if (currentUser) {

          try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/codesave`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ uid: currentUser.uid, inviteLink: inviteLink, invitee: invitee }),
            });
  
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
  
            // If the Code was successfully created, you can now safely access it
            // ...
  
          } catch (error) {
            console.error('Error creating Code:', error);
          }
          
        }
      }
  });


    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const sendTokenToServer = async (user) => {
    const idToken = await user.getIdToken(true);
    // const uniqueLink = localStorage.getItem('uniqueLink');

    // Check that the ID token is a string
    // if (typeof user.body !== 'string') {
    //   console.error('ID token is not a string:', user.body);
    //   return;
    // }

    // console.log('Sending token to server...')
    // console.log(user)

    // Send the ID token to your server
    fetch(`${process.env.REACT_APP_BASE_URL}/auth/firebase`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({idToken}),

    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Server response:", data);
        localStorage.setItem('userID', data.u_id);

        // uncomment when you want to jump to the connect socials pages instead
        if (data.redirectUrl) {
          // Navigate to the next page
          // console.log("Redirecting to:", data.redirectUrl)
          // window.opener.document.location.href = data.redirectUrl; //if you would like to change the redirect url, change it from auth/firebase in server.js
          //document.location.assign(data.redirectUrl); //changing it to .assign for Safari compatibility
          navigate(data.redirectUrl);
        }
        else{
          window.location.href = `/connect`; //in case the server takes too long to send the request
        }
      })
      .catch((error) => {
        console.error("Error sending ID token to server:", error);
      });
  };
 
  const loginWithGoogle = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        // console.log(result.user);
        result.user.getIdToken().then((idToken) => {
          // console.log("ID Token:", idToken);
          sendTokenToServer(result.user).then(() => {
            setUser(result.user);
            // navigate('/connect-youtube');
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loginWithEmail = (event) => {
    event.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("Firebase User:", user);
        sendTokenToServer(user).then(() => {
          // navigate('/connect-youtube');
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const registerWithEmail = (event) => {
    event.preventDefault();

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Successfully registered
        const user = userCredential.user;
        console.log(user);
        sendTokenToServer(user).then(() => {
          // navigate('/connect-youtube');
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <UserContext.Provider value={user}>
    {/* for adding dynamic waves */}
    {/*         <div class="ocean">
          <div class="wave"></div>
          <div class="wave wave2"></div>
        </div> */}
    <div className="container" style={{ backgroundImage: `url(${Background})`, paddingTop:'80px' }}>
      <h1 className="fade-in">Welcome to Flair!</h1>
      <p className="fade-in">A New Way to Meet People</p>
      {!user && <button className="google-btn fade-in" onClick={loginWithGoogle}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{ display: "block", paddingRight: "10px", width: "30px" }}
        >
          <path
            fill="#EA4335"
            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
          />
          <path
            fill="#4285F4"
            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
          />
          <path
            fill="#FBBC05"
            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
          />
          <path
            fill="#34A853"
            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
          />
          <path fill="none" d="M0 0h48v48H0z" />
        </svg>
        Sign in with Google
      </button>
      }
      {/* <form onSubmit={loginWithEmail}>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
      <button type="submit">Login with Email</button>
    </form>
    <button onClick={registerWithEmail}>Register with Email</button> */}
    
      <button className="fade-in2" onClick={() => navigate('/success')} id="skip">
        {user ? "Continue >" : null}
      </button> 
    </div>

    <div className="separator"></div>
      <div className="container-2" id="new">
        <h1>What's Flair ?</h1>
        <br></br>
        <br></br>
        <p>
          Discover meaningful connections with Flair, where your interests
          become a gateway to new friendships and exciting encounters. Our
          innovative platform utilizes your preferences and activities from YouTube and Spotify to match you with like-minded individuals, ensuring a personalized and enjoyable experience.
        </p>
        <p>
          Imagine a place where your favorite music and videos help you meet people who share your passions. 
        </p>

        <h2> That's Flair!</h2>

        <p>
          We use the magic of your YouTube and Spotify accounts to create a profile that reflects your true self and then introduce you to others who are on the same wavelength.
        </p>
        <p>
          Get ready to embark on
          a new way to meet people, powered by Flair!
        </p>
    </div>

    </UserContext.Provider>
  );
}

export default LoginForm;