import React from "react";
import { useNavigate } from "react-router-dom";

const KnowYourself = () => {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate("/register");
  };

  return (
    <div>
      <p>If you want to understand yourself, finish your registration. You will see what your YouTube subscriptions say about you, cool new song recommendations based on your most listened songs, and more.</p>
      <button onClick={handleContinue}>Continue</button>
    </div>
  );
};

export default KnowYourself;