// Connect.js
import React, { useState, useEffect } from "react";
import { auth } from "../config/firebase-config";

function Connect({ formData, nextStep, handleChange }) {
  const [user, setUser] = useState(null);
  const [spotifyConnected, setSpotifyConnected] = useState(null);
  const [youtubeConnected, setYoutubeConnected] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const success = params.get('success') === 'true'; 

  // Use the success state to control the animation
  const [showPage, setShowPage] = useState(!success);

  useEffect(() => {
    // Uncomment if you want to create the grayed object using ?youtubeConnected=true
    // const urlParams = new URLSearchParams(window.location.search);
    // const spotifyConnectedParam = urlParams.get('spotifyConnected');
    // const youtubeConnectedParam = urlParams.get('youtubeConnected');

    // if (spotifyConnectedParam === 'true') {
    //   localStorage.setItem(`spotifyConnected_${user.uid}`, "true");
    // }

    // if (youtubeConnectedParam === 'true') {
    //   localStorage.setItem(`youtubeConnected_${user.uid}`, "true");
    // }

    // Set an observer on the Auth object to get the current user
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      //   console.log("Current User:", currentUser);
      if (!currentUser) {
        alert("Please log in to Flair to continue");
        window.location.href = `/`;
      } else {
        setSpotifyConnected(
          localStorage.getItem(`spotifyConnected_${currentUser.uid}`) === "true"
        );
        setYoutubeConnected(
          localStorage.getItem(`youtubeConnected_${currentUser.uid}`) === "true"
        );
      }
      if (currentUser && spotifyConnected && youtubeConnected) {
        window.location.href = `/success`;
      }
    });

    // Cleanup the observer when the component is unmounted
    return () => unsubscribe();
  }, []);

  // Actually using Spotify API to connect to Spotify
  const connectSpotify = () => {
    console.log("connectSpotify is called");
    window.location.href = `${process.env.REACT_APP_BASE_URL}/connect-spotify?uid=${user.uid}`;
    localStorage.setItem(`spotifyConnected_${user.uid}`, "true");
  };

  // // Google Form
  // const connectSpotify = () => {
  //   console.log("connectSpotify is called");
  //   window.open("https://docs.google.com/forms/d/e/1FAIpQLSeD2EXA07j_od9u1-9YOTrJdyGszfqPSHIycryPXvrp3HAw_A/viewform", "_blank");
  //   localStorage.setItem(`spotifyConnected_${user.uid}`, "true");
  // };



  const connectYouTube =  async () => {
    console.log("connectYouTube is called");
    // Redirect the user to your server to initiate the OAuth 2.0 flow
    window.location.href = `${process.env.REACT_APP_BASE_URL}/api/connect-youtube?uid=${user.uid}`; // Replace with your server URL
    localStorage.setItem(`youtubeConnected_${user.uid}`, "true");
    // We cannot fetch in this case because we need to redirect the user to the server to initiate the OAuth 2.0 flow.
    // This is not something that can be done via an AJAX request like fetch
  };

  console.log("spotifyConnected:", spotifyConnected);
  console.log("youtubeConnected:", youtubeConnected);

  return (
    <div className="gradient-background-5">
      <h2>Connect Your Socials</h2>
      <div className="gender-container" id="social">
        <button
          // className={selectedGender === 'male' ? 'button-selected' : 'button-unselected'}
          className={youtubeConnected ? "button-disabled" : ""}
          onClick={connectYouTube}
        >
          <svg
            style={{ color: "#F31C1C" }}
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="100"
            fill="currentColor"
            className="bi bi-youtube"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"
              fill="red"
            ></path>
          </svg>
          YouTube
        </button>

        <button
          // className={selectedGender === 'female' ? 'button-selected' : 'button-unselected'}
          className={spotifyConnected ? "button-disabled" : ""}
          onClick={connectSpotify}
          //   disabled={spotifyConnected}
        >
          <svg
            width="70"
            height="100"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            viewBox="0 0 25 25"
            padding="10px"
          >
            <path
              d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z"
              fill="#1DB954"
            />
          </svg>
          Spotify
        </button>
      </div>
      <div className="centered-text">
        <div className="centered-text">
          {(spotifyConnected || youtubeConnected) && (
            <a href="/success" id="skip" className="fade-in2">
              {spotifyConnected && youtubeConnected ? "Next" : "Skip"} {">"}
            </a>
          )}
        </div>
        <div className="message">
          {(success) && (
              <p>Success!</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Connect;
