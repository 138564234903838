// LocationEnableForm.js
import React, {useState, useEffect} from 'react';
import '../../styles/ConnectYouTube.css';


function LocationEnableForm({ formData, nextStep, handleChange }) {
  const [error, setError] = useState('');
  const location = formData ? formData.location : '';
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationError, setLocationError] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  useEffect(() => {
    if (query.length > 2) {
      fetch(`${process.env.REACT_APP_BASE_URL}/cities?search=${query}`)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          setSuggestions(data);
        })
        .catch((err) => console.error(err));
    } else {
      setSuggestions([]);
    }
  }, [query]);

  const handleSelect = (location) => {
    setQuery(`${location.city}, ${location.state}, ${location.country}`);
    setSelectedLocation(location);
    // console.log('Selected Location:', location);
    handleChange('location', location);
    setSuggestions([]);
    setFocusedIndex(-1);
  };


  const getLocation = () => {
    setError('Connecting...');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        //getting latitude and longitude using the native js function for geolocation
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        console.log('Latitude:', latitude, 'Longitude:', longitude);

        // fetching the city using the open street map api
        fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`)
          .then(response => response.json())
          .then(data => {
            // const city = data.address.city;
            // If you want state, and country, you can do:
            const city = `${data.address.city}, ${data.address.state}, ${data.address.country}`;
            handleChange('location', { city, latitude, longitude });
            setError('Connected!');
            // nextStep();
          }
          )
          .catch(error => {//add the manual location feature here as well
            console.error(error);
            setError('Please enable location sharing on your browser and try again');
            setLocationError(true)
          });
      }, (error) => {//set up manual location in case of all errors
        console.error('Error getting location:', error);
        setError('Find your city below');
        setLocationError(true);
      });
    } else {
      //set up the manual location adding here
      console.error('Geolocation is not supported by this browser.');
      setError('Find your city below');
      setLocationError(true);
    }
  };

  const handleLocationChange = (e) => {
    setQuery(e.target.value);
  };
  
  const handleLocationSubmit = (e) => {
    handleChange('location', selectedLocation);
    if (selectedLocation) {
      nextStep();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      // Prevent cursor from moving
      e.preventDefault();
      setFocusedIndex(f => (f < suggestions.length - 1 ? f + 1 : f));
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex(f => (f > 0 ? f - 1 : 0));
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (focusedIndex >= 0 && focusedIndex < suggestions.length) {
        handleSelect(suggestions[focusedIndex]);
      }
    }
  };

  return (
    <div className="centered-text fade-in2">
      <h2>Let's Find Some People Around You</h2>
      {/* <button
        id="location"
        className="fade-in2"
        onClick={getLocation}
        onKeyDown={(e) => {
          if (e.key === "Enter") nextStep();
        }}
      >
        Share Location
      </button> */}

      {/* {error && <p style={{ color: "white" }}>{error}</p>} */}
        <div className="name-container">
          <input
            type="text"
            value={query}
            onChange={handleLocationChange}
            onKeyDown={handleKeyDown}
            placeholder="Enter Your Current City Name"
            className="fade-in2"
          />
        </div>
      {suggestions.length > 0 && (
        <ul>
          {suggestions.map((location, index) => (
            <li
              key={index}
              style={{
                backgroundColor: index === focusedIndex ? "#f0f0f0" : "white", color:"black"
              }}
              onClick={() => handleSelect(location)}
              ref={(el) => {
                if (index === focusedIndex && el) {
                  el.scrollIntoView({ behavior: "smooth", block: "nearest" });
                }
              }}
            >
              {`${location.city}, ${location.state}, ${location.country}`}
            </li>
          ))}
        </ul>
      )}
      <button className="fade-in2" id="select" onClick={handleLocationSubmit}>
          Select
      </button>
    </div>
  );
}

export default LocationEnableForm;



// AVAILABLE DATA FROM OPEN STREET MAP API

// {place_id: 28030540, licence: "Data © OpenStreetMap contributors, ODbL 1.0. http://osm.org/copyright",…}
// address
// :
// {building: "Sidney Lu Mechanical Engineering Building", house_number: "1206",…}
// ISO3166-2-lvl4
// :
// "US-IL"
// building
// :
// "Sidney Lu Mechanical Engineering Building"
// city
// :
// "Urbana"
// country
// :
// "United States"
// country_code
// :
// "us"
// county
// :
// "Champaign County"
// house_number
// :
// "1206"
// postcode
// :
// "61801"
// road
// :
// "West Green Street"
// state
// :
// "Illinois"
// addresstype
// :
// "building"
// boundingbox
// :
// ["40.1106730", "40.1112980", "-88.2253433", "-88.2240776"]
// class
// :
// "building"
// display_name
// :
// "Sidney Lu Mechanical Engineering Building, 1206, West Green Street, Urbana, Champaign County, Illinois, 61801, United States"
// importance
// :
// 0.00000999999999995449
// lat
// :
// "40.1109852"
// licence
// :
// "Data © OpenStreetMap contributors, ODbL 1.0. http://osm.org/copyright"
// lon
// :
// "-88.22519240286493"
// name
// :
// "Sidney Lu Mechanical Engineering Building"
// osm_id
// :
// 137918154
// osm_type
// :
// "way"
// place_id
// :
// 28030540
// place_rank
// :
// 30
// type
// :
// "university"