// IntentionSelectionForm.js
// ... existing code ...

function IntentionSelectionForm({ formData, nextStep, handleChange }) {
  const intention = formData ? formData.intent : '';

  const handleIntentionChange = (selectedIntent) => {
    // setSelectedIntent(selectedIntent);
    handleChange('intent', selectedIntent, nextStep);
    // nextStep();
  };

  return (
    <div>
      <h2 className="fade-in2">I am looking to...</h2>
      <div className="gender-container fade-in2">
        <button 
        onClick={() => handleIntentionChange('Date')} 
        onKeyDown={(e) => {
          if (e.key === "Enter")
            nextStep();
        }}>Date<br></br>👫</button>
        <button 
        onClick={() => handleIntentionChange('Friends')} 
        onKeyDown={(e) => {
          if (e.key === "Enter")
            nextStep();
        }}>Meet People<br></br>🏇🏻</button>
        <button 
        onClick={() => handleIntentionChange('Either')} 
        onKeyDown={(e) => {
          if (e.key === "Enter")
            nextStep();
        }}>Surprise Me<br></br>✨🤭</button>
      </div>
    </div>
  );
}

export default IntentionSelectionForm;