import react from "react";
import "../styles/Misc.css";
import Background from '../components/about-waves.svg'

const TOS = () => {
  return (
    <div style={{backgroundImage:`url(${Background})`, backgroundAttachment: 'fixed'}}>
    <div className="tos fade-in">
      <h1>Terms of Service</h1>

      <p>
        By using Flair, you agree to comply with and be bound by the following
        terms and conditions. Please read these terms carefully before accessing
        or using our services.
      </p>
      <br></br>

      <h3>1. Acceptance of Terms</h3>

      <p>
        By accessing or using Flair, you acknowledge that you have read,
        understood, and agree to be bound by these terms. If you do not agree
        with any part of these terms, you may not use our services.
      </p>

      <h3>2. User Responsibilities</h3>

      <p>
        You are responsible for maintaining the confidentiality of your account
        and password. You agree to accept responsibility for all activities that
        occur under your account.
      </p>

      <h3>3. User Conduct</h3>

      <p>
        You agree not to use Flair for any unlawful purpose or any purpose
        prohibited by these terms. You may not use Flair in any manner that
        could damage, disable, overburden, or impair our services.
      </p>

      <h3>4. Privacy</h3>

      <p>
        Your use of Flair is also governed by our Privacy Policy. Please review
        our Privacy Policy to understand our practices.
      </p>

      <h3>5. Modification of Terms</h3>

      <p>
        Flair reserves the right to modify or replace these terms at any time.
        Your continued use of our services after any changes to these terms
        constitutes acceptance of those changes.
      </p>

      <h3>6. Termination</h3>

      <p>
        Flair reserves the right to terminate your access to our services for
        any reason without notice.
      </p>

      <h3>7. Disclaimer</h3>

      <p>
        Flair provides its services "as is" and makes no warranties, expressed
        or implied, regarding the accuracy or reliability of our services.
      </p>

      <h3>8. Governing Law</h3>

      <p>
        These terms are governed by and construed in accordance with the laws of
        the United States, without regard to its conflict of law provisions.
      </p>
    </div>
    </div>
  );
};

export default TOS;
