import react from "react";
import "../styles/Misc.css";
import Background from '../components/about-waves.svg'

const Contact = () => {
  return (
    <div className="container" style={{backgroundImage:`url(${Background})`, backgroundAttachment: 'fixed'}}>
      <div className="tos fade-in" id="about" style={{justifyContent:"center"}}>
      <h1 style={{marginBottom:"20px" }}>Questions?</h1>
        <p id="contact">
            Please send us an email at{" "}
            <a href="mailto:letsflair.inc@gmail.com" style={{color: 'rgb(27, 216, 245)', fontWeight:"bolder"}}>letsflair.inc@gmail.com</a>
        </p>
    </div>
    </div>
  );
};

export default Contact;
