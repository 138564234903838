// frontend/client/src/firebase-config.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyA1Q9HFHVVK9cfrZN2FL-YUqH7Yup7yjmw",
    authDomain: "flair-405423.firebaseapp.com",
    databaseURL: "https://flair-405423-default-rtdb.firebaseio.com/",
    projectId: "flair-405423",
    storageBucket: "flair-405423.appspot.com",
    messagingSenderId: "193002709960",
    appId: "1:193002709960:web:ba9e1808293d6f310516fd",
    measurementId: "G-0W7BPWHJKJ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { app, auth, googleProvider };

// export const auth = firebase.auth();
// export const googleProvider = new firebase.auth.GoogleAuthProvider();
