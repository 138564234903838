// InterestsForm.js
import React, { useState, useEffect } from 'react';

function InterestsForm({formData, onSubmit, handleChange}) {
  const interest = formData ? formData.interest : '';
  const [selectedInterests, setSelectedInterests] = useState([]);

  const interests = [
    { name: "Art", emoji: "🖼️" },
    { name: "Science and Tech", emoji: "💻" },
    { name: "Artificial Intelligence", emoji: "🤖" },
    { name: "Space", emoji: "🚀" },
    { name: "Food", emoji: "🥙" },
    { name: "Music", emoji: "🎵" },
    { name: "Sports", emoji: "⚽️" },
    { name: "Video Games", emoji: "🎮" },
    { name: "TV and Movies", emoji: "🎥" },
    { name: "Anime", emoji: "🈸" },
    { name: "Politics", emoji: "🗣️" },
    { name: "Economics", emoji: "💲" },
    { name: "Books", emoji: "📚" },
    { name: "Animals and Pets", emoji: "🦮" },
    { name: "Health and Fitness", emoji: "🏋🏻‍♂️" },
    { name: "Fashion", emoji: "👗" },
    { name: "Nature", emoji: "🌲" },
    // ...other interests
  ];

  const handleInterestChange = (name) => {
    if (selectedInterests.includes(name)) {
      setSelectedInterests(selectedInterests.filter(interest => interest !== name));
    } else {
      setSelectedInterests([...selectedInterests, name]);
      // handleChange('interest', selectedInterests);
    }
  };

  useEffect(() => {
    handleChange('interest', selectedInterests);
  }, [selectedInterests]);

  const handleContinue = () => {
    // Handle continue action
  };

  return (
    <div>
      <h2 className="fade-in2">Select Your Interests</h2>
      <div className="gender-container fade-in2" id='interests'>
        {interests.map((interest, index) => (
          <button
            key={index}
            className={selectedInterests.includes(interest.name) ? 'button-selected' : 'button-unselected'}
            onClick={() => handleInterestChange(interest.name)}
            onKeyDown={(e) => {
              if (e.key === "Enter")
                onSubmit();
            }}
          >
            {interest.emoji}<br></br>{interest.name}
          </button>
        ))}
      </div>
      {/* {interests.map((interest, index) => (
        <div key={index}>
          <input
            type="checkbox"
            id={interest.name}
            name={interest.name}
            checked={selectedInterests.includes(interest.name)}
            onKeyDown={(e) => {
              if (e.key === "Enter")
                onSubmit();
            }}
            onChange={() => handleInterestChange(interest.name)}
          />
          <label htmlFor={interest.name} style={{ textShadow: '1px 1px white' }}>{interest.emoji} {interest.name}</label>
        </div>
      ))} */}
      {/* <button style={{ backgroundColor: 'red', color: 'white', boxShadow: '2px 2px black' }} onClick={handleContinue}>Continue</button> */}
    </div>
  );
}

export default InterestsForm;