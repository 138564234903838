import React, { useState, useEffect } from 'react';
import { auth } from '../../config/firebase-config';
import { TextField, Button, FormControl, InputLabel, MenuItem, Select, Box, Chip, Input } from '@mui/material';
import './Profile.css';

const EditProfile = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const cachedUser = localStorage.getItem('user');
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    gender: '',
    intent: '',
    interest: [],
    dob: '',
    matchType: '',
    phoneNumber: '',
  });

  const names = [
    "Art",
    "Science and Tech",
    "Artificial Intelligence",
    "Space",
    "Food",
    "Music",
    "Sports",
    "Video Games",
    "TV and Movies",
    "Anime",
    "Politics",
    "Economics",
    "Books",
    "Animals and Pets",
    "Health and Fitness",
    "Fashion",
    "Nature",
  ];
  
  // console.log("INTERESTS: ", formData.interest)
  useEffect(() => {
    const fetchData = async () => {
      try {
        if(cachedUser){
          const parsedUser = JSON.parse(cachedUser);
          setUser(parsedUser);
          setFormData({
              email: parsedUser.email,
              firstName: parsedUser.user.firstName,
              lastName: parsedUser.user.lastName,
              gender: parsedUser.user.gender,
              intent: parsedUser.user.intent,
              matchType: parsedUser.user.matchType,
              interest: parsedUser.user.interest,
              dob: parsedUser.user.dob || '', 
              phoneNumber: parsedUser.user.phoneNumber,
            });
          return;
        }
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
          setUser(currentUser);
          console.log('Current User:', currentUser);
          if (currentUser) {
            const uid = currentUser.uid;
            console.log('Calling backend');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/get-user?uid=${uid}`);
            const data = await response.json();
            setFormData({
              email: data.userDoc.email,
              firstName: data.userDoc.user.firstName,
              lastName: data.userDoc.user.lastName,
              gender: data.userDoc.user.gender,
              intent: data.userDoc.user.intent,
              matchType: data.userDoc.user.matchType,
              interest: data.userDoc.user.interest,
              dob: data.userDoc.user.dob || '', 
              phoneNumber: data.userDoc.user.phoneNumber,
            });
          } else {
            console.error('User is not logged in');
          }
        });

        return () => unsubscribe();
      } catch (error) {
        console.error('Error:', error);
        setError(error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Updated Profile Data:', formData);
    
    if (user) {
      console.log("User is signed in");

      const url = `${process.env.REACT_APP_BASE_URL}/register?uid=${user.firebase.uid}`;
      const data = {
        dob: formData.dob,
        phoneNumber: formData.phoneNumber,
        gender: formData.gender,
        location: formData.location,
        firstName: formData.firstName,
        lastName: formData.lastName,
        interest: formData.interest,
        intent: formData.intent,
        matchType: formData.matchType,
        phoneExtension: "",
      };

      console.log("Sending data to server:", data);

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Server response:", data);
          localStorage.removeItem('user'); //since it was updated, removing it from cache
          window.location.href = "/profile";
        })
        .catch((error) => {
          console.error("Error:", error);
        });

    }
  };

  return (
    <div className="edit">
      <h2>Edit Profile</h2>
      <form onSubmit={handleSubmit}>
        <div className='edit-form'>
          <TextField sx={{ width: 300 }} label="Email" type="email" name="email" value={formData.email} onChange={handleInputChange} variant="standard" disabled/>
          <TextField sx={{ width: 300 }} label="First Name" type="text" name="firstName" value={formData.firstName} onChange={handleInputChange} variant="standard" />
          <TextField sx={{ width: 300 }} label="Last Name" type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} variant="standard" />
          <TextField
            label="Date of Birth (mm-dd-yyyy)"
            type="text"
            name="dob"
            value={formData.dob}
            variant="standard"
            disabled
          />
          <TextField sx={{ width: 300 }} label="Phone number" type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} variant="standard" inputProps={{ maxLength: 13 }}/>
          <FormControl sx={{ width: 300 }} variant="standard">
            <InputLabel id="gender-label">Gender</InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Non-binary">Non-binary</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: 300 }} variant="standard">
            <InputLabel id="intent-label">Preference</InputLabel>
            <Select
              labelId="intent-label"
              id="intent"
              name="intent"
              value={formData.intent}
              onChange={handleInputChange}
            >
              <MenuItem value="Date">Dating</MenuItem>
              <MenuItem value="Friends">Friends</MenuItem>
              <MenuItem value="Either">Surprise Me ✨</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: 300 }} variant="standard">
            <InputLabel id="match-label">Match Type</InputLabel>
            <Select
              labelId="match-label"
              id="matchType"
              name="matchType"
              value={formData.matchType}
              onChange={handleInputChange}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Either">Either</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: 300 }} variant="standard">
            <InputLabel id="interests-label">Interests</InputLabel>
            <Select
              labelId="interests-label"
              id="interests"
              name="interest"
              multiple
              value={formData.interest}
              onChange={handleInputChange}
              input={<Input id="select-multiple-chip" label="Interests" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {names.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button sx={{ mb: 8 }} type="submit" variant="contained" color="primary">Update</Button>
        </div>
      </form>
      {/* {error && <p>Error: {error.message}</p>} */}
    </div>
  );
};

export default EditProfile;
