import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./components/Home";
import ConnectYouTube from "./components/ConnectYouTube";
import LoginForm from "./components/LoginForm";
import Subscriptions from "./components/Subscriptions";
import SuccessPage from './components/SuccessPage';
import { app, auth, googleProvider } from "./config/firebase-config";
import { onAuthStateChanged, signOut } from "firebase/auth";
import Navbar from "./components/Navbar";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SpotifyAuthScreen from "./components/Spotify";
import PhoneNumberForm from "./components/signup/PhoneNumberForm";
import AgeDropDownForm from "./components/signup/AgeDropDownForm";
import RegistrationForm from "./components/RegistrationForm";
import KnowYourself from "./components/knowyourself";
import AlgorithmsYou from "./components/AlgorithmsYou";
import Connect from "./components/Connect";
import TOS from "./components/TermsOfService";
import About from "./components/About";
import Contact from "./components/Contact";
import Profile from "./components/profile/Profile";
import EditProfile from "./components/profile/EditProfile";
import ReferralHandler from "./components/ReferralHandler";
import Leaderboard from "./components/Leaderboard";
import Avivox from "./components/Avivox";

import "./index.css"

function App() {
  // useEffect(() => {
  //   fetch("https://flair-yvse.onrender.com/").then((res) => console.log(res));
  // }, []);

  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       // If a user is signed in, check if they have connected their YouTube account
  //       fetch(`${process.env.REACT_APP_BASE_URL}/api/check-youtube-connection`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ userId: user.uid }),
  //       })
  //         .then(response => response.json())
  //         .then(data => {
  //           if (data.success) {
  //             // If the YouTube account is connected, redirect to the success page
  //             navigate('/success');
  //           } else {
  //             // If the YouTube account is not connected, handle the error
  //             console.error('YouTube account not connected:', data.error);
  //           }
  //         })
  //         .catch(error => {
  //           console.error('Error making API call:', error);
  //         });
  //     }
  //   });
  //   // Cleanup subscription on unmount
  //   return () => unsubscribe();
  // }, [navigate]);

  // create footer

  // <footer>
  //   <p>&copy; 2024 Flair</p>
  //   <a href="/privacy">Privacy Policy</a>
  //   <a href="/tos">Terms of Service</a>

  // </footer>

  return (
    <div className="App">
      <Navbar user={user} />
      <Routes>
        <Route path="/" element={<LoginForm />} /> {/* use the new component */}
        <Route path="/home" element={<Home />} />
        <Route path="/connect-youtube" element={<ConnectYouTube />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/spotify" element={<SpotifyAuthScreen />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/register" element={<RegistrationForm />} />
        {/* <Route path="/phone-number" element={<PhoneNumberForm />} />
        <Route path="/age" element={<AgeDropDownForm />} /> */}
        <Route path="/knowyourself" element={<KnowYourself />} />
        <Route path="/algorithmsyou" element={<AlgorithmsYou />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/terms" element={<TOS />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/code" element={< ReferralHandler/>} />
        <Route path="/leaderboard" element={< Leaderboard />} />
        <Route path="/avivox" element={<Avivox />} />
      </Routes>
    </div>
  );
}

export default App;
