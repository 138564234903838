import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './config/firebase-config';
import App from './App';
import { BrowserRouter } from 'react-router-dom';


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />

      {/* <footer>
        <p>&copy; 2024 Flair</p>
        <p><a href="/privacy" style={{backgroundColor: 'white', color: 'blue'}}>Privacy Policy</a></p>
        <p><a href="/tos" style={{backgroundColor: 'white', color: 'blue'}}>Terms of Service</a></p>
      


      </footer> */}

      <footer style={{display:"flex"}}>
        <div className="footer-container">
        <p>
          © {new Date().getFullYear()} Flair Inc. All rights reserved.
        </p>
        <div className="footer-links">
          <a href="/about">About</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms of Service</a>
          <a href="/contact">Contact</a>
        </div>
        </div>
      </footer>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
