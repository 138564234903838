import React, { useEffect, useState } from 'react';
import { app, auth, googleProvider } from '../config/firebase-config';
import { Link } from 'react-router-dom';

function SpotifyAuthScreen() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Set an observer on the Auth object to get the current user
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      console.log('Current User:', currentUser);
    });

    // Cleanup the observer when the component is unmounted
    return () => unsubscribe();
  }, []);

  const connectSpotify = () => {
    if (user) {
      console.log('User is signed in');
      window.location.href = `${process.env.REACT_APP_BASE_URL}/connect-spotify?uid=${user.uid}`; 
    }
  };

  return (
    <div class="gradient-background-3">
      <h1>Connect</h1>
      <p>Your Socials (2/2)</p>
      <button className="styled-button" onClick={connectSpotify}>
        Spotify
      </button>
      <a href="/success" id='skip'>Skip</a> {/* Add this line */}
    </div>
  );
}

export default SpotifyAuthScreen;