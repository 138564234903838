// frontend/client/src/components/profile/Profile.js
import React, { useState, useEffect } from 'react';
import { auth } from '../../config/firebase-config';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button, Chip } from '@mui/material';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [profileStatus, setProfileStatus] = useState(true);
  const [tags, setTags] = useState([]);
  const [checkSpotify, setCheckSpotify] = useState(false);
  const [checkYouTube, setCheckYouTube] = useState(false);
  const [uniqueLink, setUniqueLink] = useState(null);
  const [checkUniqueLink, setCheckUniqueLink] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if user data exists in localStorage
        const cachedUser = localStorage.getItem('user');
        const cachedTags = localStorage.getItem('tags');
        const cachedLink = localStorage.getItem('myLink');
        if (cachedUser && cachedTags && cachedLink) {
          const parsedUser = JSON.parse(cachedUser);
          const parsedLink = cachedLink;
          setUser(parsedUser);
          setTags(JSON.parse(cachedTags));
          setUniqueLink(parsedLink);
          setCheckUniqueLink(true);
          if(!parsedUser.user){
            setProfileStatus(false);
          }
          return;
        }
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
          setUser(currentUser);
          if (currentUser) {
            const uid = currentUser.uid;
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/get-user?uid=${uid}`);
            const data = await response.json();

            setUser(data.userDoc);
            console.log('user profile acquired:', data.userDoc)
            localStorage.setItem('user', JSON.stringify(data.userDoc)); // Cache user data
            localStorage.setItem('userID', data.userDoc._id);

            if (!data.userDoc.user) {
              setProfileStatus(false);
            }

            if (data.userDoc.spotify && data.userDoc.spotify.topArtists.length) {
              const tagsResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/top-artists-tags?uid=${uid}`);
              const tagsData = await tagsResponse.json();
              setTags(tagsData.topArtistsTags);
              localStorage.setItem('tags', JSON.stringify(tagsData.topArtistsTags));
              setCheckSpotify(true);
            }

            // console.log(data.userDoc)
            setUniqueLink(data.userDoc.uniqueLink);
            localStorage.setItem('myLink', data.userDoc.uniqueLink);
            setCheckUniqueLink(true);
          } else {
            window.location.href = `/`;
          }
        });
        return () => unsubscribe();
        
      } catch (error) {
        console.error('Error fetching data from server', error);
        setError(error);
      }
    };
    fetchData();
  }, []);

  // const copyToClipboard = () => {
  //   if (uniqueLink) {
  //     navigator.clipboard.writeText(uniqueLink)
  //       .then(() => alert('Link copied to clipboard!'))
  //       .catch((err) => console.error('Error copying link:', err));
  //   }
  // };



  return (
    <div className="main-container">
      <div className="profile-container">
        <div className="left-profile">
          <div className="account-box">
            <div className="top-box">
              <code className="box-name">MY ACCOUNT</code>
              {user && user.user && (
                <Button type="submit" color="primary" href="/edit-profile">
                  Edit
                </Button>
              )}
            </div>
            <div className="main-info">
              {user && user.user && (
                <div className="name-style">
                  {user.user.firstName} {user.user.lastName}
                </div>
              )}
              {user && user.user && (
                <div className="email-style">{user.email}</div>
              )}
              {user && user.user && (
                <div className="other-style">
                  Gender:{" "}
                  <b>
                    {user.user.gender.charAt(0).toUpperCase() +
                      user.user.gender.slice(1)}
                  </b>
                </div>
              )}
              {user && user.user && (
                <div className="other-style">
                  Preference:{" "}
                  <b>
                    {user.user.intent === "Either"
                      ? "Surprise Me ✨"
                      : user.user.intent}
                  </b>
                </div>
              )}
              {user && user.user && (
                <div className="other-style">
                  Phone: <b>{user.user.phoneNumber}</b>
                </div>
              )}
              {!profileStatus && (
                <Button href="/register">Complete your profile</Button>
              )}
            </div>
          </div>

          <div className="interests-box">
            <div className="top-box">
              <code className="box-name">INTERESTS</code>
              {user && user.user && (
                <Button type="submit" color="primary" href="/edit-profile">
                  Edit
                </Button>
              )}
            </div>
            {user && user.user && (
              <div className="chips-container">
                {user.user.interest.map((interest, index) => (
                  <Chip key={index} label={interest} size="large" />
                ))}
              </div>
            )}
            {!profileStatus && (
              <Button href="/register">Complete your profile</Button>
            )}
          </div>
        </div>

        <div className="right-column">
          <div className="right-top">
            {/*
        <div className="right-profile">
          {checkSpotify ? 
          <div className="account-box" id="right">
            <div className='top-box'>
              <code className='box-name'>SPOTIFY TAGS</code>
            </div>
            <div className='chips-container'>
              {tags.map((tag, index) => (
                <Chip key={index} label={tag} size="large" />
              ))}
            </div>
          </div> : 
          <div className='account-box' id="right">
            <div className='top-box'>
              <Button href='/connect'>Connect Your Socials</Button>
            </div>
          </div>
          }
      </div>
      */}

            <div className="right-profile">
              {/* Connect with other */}
              {checkUniqueLink ? (
                <div className="account-box" id="right">
                  <div className="top-box">
                    <code className="box-name">
                      How Compatible Are You With Your Friends?{" "}
                    </code>
                  </div>
                  <div className="chips-container" id="invite">
                    {uniqueLink && (
                      <div className="link-buttons">
                        <Button
                          variant="contained"
                          color="primary"
                          id="invite"
                          onClick={() =>
                            navigator.clipboard.writeText(
                              `Hey! What's our Flair? ${process.env.REACT_APP_FRONTEND_URL}/code?${uniqueLink}`
                            )
                          }
                        >
                          Copy Invite Link
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            window.location.href = `/leaderboard`
                          }
                        >
                          Leaderboard
                        </Button>
                      </div>
                    )}
                    {/* {uniqueLink && <p>{`${process.env.REACT_APP_BASE_URL}/code?link=${uniqueLink}`}</p>} */}
                  </div>
                </div>
              ) : (
                <div className="account-box" id="right">
                  <div className="top-box">
                    <Button href="/connect">Connect Your Socials</Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="right-bottom"></div>
        </div>

        {/*  
        <div className="right-profile">
          <div className='top-box'>
            <code className='box-name'>YouTube TAGS</code>
          </div>
          <div className='chips-container'>
            {tags.map((tag, index) => (
              <Chip key={index} label={tag} size="large" />
            ))}
          </div>
        </div>
         */}
        {/* {error && <p>Error: {error.message}</p>} */}
      </div>
    </div>
  );
};

export default Profile;
