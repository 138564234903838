import react from "react";
import "../styles/Misc.css";
import Background from '../components/about-waves.svg'
import logo from '../components/logo.png'

const About = () => {
  return (
    <div style={{backgroundImage:`url(${Background})`, backgroundAttachment: 'fixed'}}>
      <div className="privacy-policy fade-in" id="about" style={{justifyContent:"center"}}>
        <h1 style={{padding:"20px", fontSize:"60px"}}>
        <div style={{paddingRight:"20px"}}>About</div>
        <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Flair
        <img src={logo} alt="description" style={{ marginLeft: "20px", width:"50px" }} /></div>
        </h1>
          <p>
            Discover meaningful connections with <b>Flair</b>, where your interests
            become a gateway to new friendships and exciting encounters. Our
            innovative platform utilizes your preferences and activities to
            match you with like-minded individuals, ensuring a personalized and
            enjoyable experience.
          </p>
          <p>
          Flair enhances user functionality by providing a unique platform where individuals can connect with others based on shared interests and activitie from your YouTube and Spotify accounts to create a profile that reflects your personality and interests and then match you with other users who share your passions.
          </p>
          <p>
            Rest assured, we prioritize your privacy, using your data
            responsibly to enhance your social journey. 
          </p>
          <h2>Get ready to embark on a new way to meet people with Flair!</h2>
          <p>
            To learn more about how we handle your data, please review our{" "}
            <a href="/privacy" style={{color: 'rgb(27, 216, 245)'}}>Privacy Policy</a>

          </p>
    </div>
    </div>
  );
};

export default About;
