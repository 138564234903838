// In your frontend component 'AlgorithmsYou.js'

import React, { useState, useEffect } from 'react';
import { auth } from '../config/firebase-config';

const AlgorithmsYou = () => {
  const [user, setUser] = useState(null);
  const [subscriptionCount, setSubscriptionCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
          setUser(currentUser);
          console.log('Current User:', currentUser);
          setLoading(false);
        });

        if (user) {
          const uid = user.uid;
          console.log('Calling backend');
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/get-subscription-count?uid=${uid}`);
          const data = await response.json();
          setSubscriptionCount(data.subscriptionCount);
        } else {
          console.error('User is not logged in');
        }

        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching subscription count:', error);
        setError(error);
      }
    };

    fetchData();
  }, [user]);

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {!loading && !error && (
        <p>User's YouTube Subscription Count: {subscriptionCount}</p>
      )}
    </div>
  );
};

export default AlgorithmsYou;
