// frontend/src/components/signup/PhoneNumberForm.js
import React from 'react';
import countryCodes from '../../country_code.json';

const PhoneNumberForm = ({ formData, nextStep, handleChange }) => {
  const phoneNumber = formData ? formData.phoneNumber : '';
  const phoneExtension = formData ? formData.phoneExtension : '+1';

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      handleChange('phoneNumber', value);
    }
  };

  const handlePhoneExtensionChange = (e) => {
    handleChange('phoneExtension', e.target.value || '+1');  //verifies with the handleChange in RegistrationForm.js
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log("Form submitted");
  //   await handleChange('phoneNumber', phoneNumber);
  //   nextStep();
  // };


  return (
    <div>
      <h2 className="fade-in2" style={{ paddingBottom: 50 }}>Enter Your Phone Number</h2>
      <div className="phone-container fade-in2">
        <select name="phoneExtension" value={phoneExtension} onChange={handlePhoneExtensionChange}>
          {countryCodes.map((country) => (
            <option key={country.code} value={country.dial_code}>
              <div>{country.flag}</div> {country.dial_code}
            </option>
          ))}
        </select>
        <input
          type="tel"
          name="phoneNumber"
          pattern="[0-9]{1,10}"
          value={phoneNumber}
          onKeyDown={(e) => {
            if (e.key === "Enter")
              nextStep();
          }}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
          }}
          onChange={handlePhoneNumberChange}
        />
      </div>
      {/* <button onClick={nextStep}>Next</button> */}
    </div>

  );
};

export default PhoneNumberForm;