// GenderForm.js
import React, {useState} from 'react';

function MatchType({ formData, nextStep, handleChange }) {
  const matchType = formData ? formData.matchType : '';
  const [selectedMatchType, setSelectedGender] = useState(null);

  const handleGenderChange = (matchType) => {
    setSelectedGender(matchType);
    // console.log(gender);
    // handleChange('gender', gender);
    handleChange('matchType', matchType, nextStep);
    // nextStep();

    // setTimeout(nextStep, 50); 
  };

  return (
    <div>
      <h2 className="fade-in2">Who would you like to be matched with?</h2>
      <div className="gender-container fade-in2">
        <button
          // className={selectedGender === 'male' ? 'button-selected' : 'button-unselected'}
          onClick={() => handleGenderChange('Male')}
          onKeyDown={(e) => {
            if (e.key === "Enter") nextStep();
          }}
        >👦<br></br>Male</button>

        <button
          // className={selectedGender === 'female' ? 'button-selected' : 'button-unselected'}
          onClick={() => handleGenderChange('Female')}
          onKeyDown={(e) => {
            if (e.key === "Enter") nextStep();
          }}
        >👧<br></br>Female</button>

        <button
          // className={selectedGender === 'non-binary' ? 'button-selected' : 'button-unselected'}
          onClick={() => handleGenderChange('Either')}
          onKeyDown={(e) => {
            if (e.key === "Enter") nextStep();
          }}
        >👦/👧<br></br>Either</button>
      </div>
    </div>
  );
}

export default MatchType;