// frontend/src/components/signup/AgeDropDownForm.js

import React, {useState} from 'react';

const AgeDropDownForm = ({ formData, nextStep, prevStep, handleChange }) => {
  const [dob, setDob] = useState('');
  const [sendDob, setSendDob] = useState(formData?.dob || '');

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const today = new Date().toISOString().split('T')[0];

  // console.log(today)

  const handleDayChange = (event) => {
    setDay(event.target.value);
    updateDOB(event.target.value, month, year);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
    updateDOB(day, event.target.value, year);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
    updateDOB(day, month, event.target.value);
  };

  //outdated
  const updateDOB = (day, month, year) => {
    if (day && month && year) {
      const dob = `${month}-${day}-${year}`;
      setDob(dob);
      handleChange('dob', dob);
    }
  };

  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  // const days = Array.from({ length: 31 }, (_, i) => i + 1);
  // const currentYear = new Date().getFullYear();
  // const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

  const handleDateChange = (event) => {
    setDob(event.target.value); //for displaying in the box
    const date = new Date(event.target.value);
    const formattedDate = `${date.getMonth() + 1}-${date.getDate() + 1}-${date.getFullYear()}`;
    // console.log('formatted date:', formattedDate);
    setSendDob(formattedDate); //sending to the record
    handleChange('dob', formattedDate);
  };

  return (
    <div>
      <h2 className="fade-in2" style={{ paddingBottom: 50 }}>Select Your Date of Birth</h2>
      <div className="gender-container fade-in2">
      <input 
          type="date" 
          value={dob} 
          max= {today}
          onChange={handleDateChange} 
          onKeyDown={(e) => {
            if (e.key === "Enter") nextStep();
          }}
        />
        {/* <select value={month} onChange={handleMonthChange}           onKeyDown={(e) => {
            if (e.key === "Enter") nextStep();
          }}>
          {months.map((month, index) => (
            <option key={index} value={month}>{month}</option>
          ))}
        </select>
        <select value={day} onChange={handleDayChange}           onKeyDown={(e) => {
            if (e.key === "Enter") nextStep();
          }}>
          {days.map((day, index) => (
            <option key={index} value={day}>{day}</option>
          ))}
        </select>
        <select value={year} onChange={handleYearChange}           onKeyDown={(e) => {
            if (e.key === "Enter") nextStep();
          }}>
          {years.map((year, index) => (
            <option key={index} value={year}>{year}</option>
          ))}
        </select> */}
      </div>
    </div>
  );
};

export default AgeDropDownForm;