// RegistrationForm.js
import React, { useState, useEffect } from "react";
import { auth } from "../config/firebase-config";
// import UserDetails from 'components/signup/PhoneNumberForm';
// import PersonalInfo from './PersonalInfo';
import PhoneNumberForm from "./signup/PhoneNumberForm";
import AgeDropDownForm from "./signup/AgeDropDownForm";
import LocationEnableForm from "./signup/LocationEnableForm";
import FirstNameForm from "./signup/FirstNameForm";
import LastNameForm from "./signup/LastNameForm";
import UserNameForm from "./signup/UserNameForm";
import GenderSelectionForm from "./signup/GenderSelectionForm";
import ProfilePictureForm from "./signup/ProfilePictureForm";
import InterestsForm from "./signup/InterestsForm";
import IntentionSelectForm from "./signup/IntentionSelectForm";
import { redirect } from "react-router-dom";
import MatchType from "./signup/MatchType";

// ... other step components

// const history = useHistory();

const RegistrationForm = ({}) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Set an observer on the Auth object to get the current user
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      // console.log('Current User:', currentUser);
      if (!currentUser) {
        alert("Please sign in to continue");
        window.location.href = `/`;
      }
    });

    // Cleanup the observer when the component is unmounted
    return () => unsubscribe();
  }, []);

  const [step, setStep] = useState(0);
  const totalSteps = 9;
  const [formData, setFormData] = useState({
    phoneNumber: "",
    phoneExtension: "+1",
    dob: "",
    location: "",
    firstName: "",
    lastName: "",
    gender: "none", //have to keep "none" as default otherwise the alert pops up even after selection
    interest: "",
    intent: "none",
    matchType: "none"
  });

  // Calculate progress as a percentage
  const progress = ((step+1) / totalSteps) * 100;

  console.log('This is the formData:',formData);

  const sendData = () => {
    if (user) {
      console.log("User is signed in");

      const url = `${process.env.REACT_APP_BASE_URL}/register?uid=${user.uid}`;
      const data = {
        dob: formData.dob,
        phoneNumber: formData.phoneNumber,
        gender: formData.gender,
        location: formData.location,
        firstName: formData.firstName,
        lastName: formData.lastName,
        interest: formData.interest,
        intent: formData.intent,
        matchType: formData.matchType,
        phoneExtension: formData.phoneExtension,
        // Add other data as needed
      };

      console.log("Sending data to server:", data);

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Server response:", data);
          window.location.href = "/success";
          // Handle the server response as needed
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  // const handleChange = (input, value, callback) => {
  //   setFormData(prevState => {
  //     const updatedFormData = { ...prevState, [input]: value };
  //     if (typeof callback === 'function') {
  //       callback();
  //     }
  //     return updatedFormData;
  //   });
  // };

  // const handleChange = (input, value, callback) => {
  //   return new Promise(resolve => {
  //     setFormData(prevState => {
  //       const updatedFormData = { ...prevState, [input]: value };
  //       resolve(updatedFormData);
  //       if (typeof callback === 'function') {
  //         callback();
  //       }
  //       return updatedFormData;
  //     });
  //   });
  // };

  const handleChange = (input, value, callback) => {
    setFormData((prevState) => {
      const updatedFormData = { ...prevState, [input]: value };
      callback && callback();
      return updatedFormData;
    });
  };

  const handleNext = async (e, callback) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    // Call the callback function to update formData
    // callback && callback();

    // let updatedFormData = formData;
    // if (callback) {
    //   updatedFormData = await callback();
    // }

    const {
      firstName,
      lastName,
      dob,
      gender,
      location,
      phoneNumber,
      intent,
      interest,
      matchType,
    } = formData;

    if (step === 0 && (!firstName || !lastName)) {
      alert("First name and last name are required");
    } else if (step === 1 && !dob) {
      alert("Please Enter a Valid Date of Birth");
    } else if (step === 2 && !gender) {
      alert("Gender is required");
    } else if ((step === 3 && !phoneNumber) || (step === 3 && phoneNumber.length < 10)) {
      alert("Phone number is required");
    } else if (step === 4 && !location) {
      alert("Location is required");
    } else if (step === 5 && !intent) {
      alert("Please select one");
    } else if (step === 6 && !matchType) {
      alert("Please select one");
    } else if (step === 7 && !interest) {
      alert("Select at least one interest");
    } else {
      setStep(step + 1);
    }
    // sendData();
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const handleSubmit = () => {
    // Submit formData to the server
    sendData();
    // window.location.href = `/connect-youtube`;
    // window.location.href = `/connect-youtube`;

    // console.log(formData);
    // You can use axios or fetch to send the form data to the server
  };

  const steps = [
    <FirstNameForm
      formData={formData}
      handleChange={handleChange}
      onPrev={handlePrev}
      nextStep={handleNext}
    />,
    <AgeDropDownForm
      formData={formData}
      handleChange={handleChange}
      nextStep={handleNext}
      onPrev={handlePrev}
    />,
    <GenderSelectionForm
      formData={formData}
      handleChange={handleChange}
      nextStep={handleNext}
      onPrev={handlePrev}
    />,
    <PhoneNumberForm
      formData={formData}
      handleChange={handleChange}
      nextStep={handleNext}
      onPrev={handlePrev}
    />,
    <LocationEnableForm
      formData={formData}
      handleChange={handleChange}
      nextStep={handleNext}
      onPrev={handlePrev}
    />,
    // <UserNameForm formData={formData} handleChange={handleChange} nextStep={handleNext} onPrev={handlePrev}  handleSubmit={handleNext}/>,
    // <ProfilePictureForm formData={formData} handleChange={handleChange} nextStep={handleNext} onPrev={handlePrev} />,
    <IntentionSelectForm
      formData={formData}
      handleChange={handleChange}
      nextStep={handleNext}
      onPrev={handlePrev}
    />,
    <MatchType
      formData={formData}
      handleChange={handleChange}
      nextStep={handleNext}
      onPrev={handlePrev}
    />,
    <InterestsForm
      formData={formData}
      handleChange={handleChange}
      onSubmit={handleSubmit}
    />,
  ];

  return (
    <div className="gradient-background-4">
      <div className="centered-text">
        {/* Progress bar */}
        <div className="progress-window">
          <div className="progress-bar"
            style={{
              width: `${progress}%`,
            }}
          ></div>
        </div>
        {steps[step]}
        <div className="button-container">
          {step > 0 ? (
            <button className="prev-button" onClick={handlePrev}>
              &lt; PREV
            </button>
          ) : (
            <div></div> // Empty div when there's no previous button
          )}
          {step < steps.length - 1 ? (
            <button className="next-button" onClick={() => handleNext({})}>
              NEXT &gt;
            </button>
          ) : (
            <button className="submit-button" onClick={handleSubmit}>
              Finish
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
