import React, { useEffect, useState } from "react";
import { auth } from "../config/firebase-config";
import { Link } from "react-router-dom";
import { Button, Chip } from '@mui/material';

const Leaderboard = () => {
  const [user, setUser] = useState(null);
  const [profileStatus, setProfileStatus] = useState(true);
  const [socialStatus, setSocialStatus] = useState(true);
  const [userID, setUserId] = React.useState(localStorage.getItem('userID')); //ObjectId, not the UID
  const [connections, setConnections] = useState(null);
  const cachedConnections = localStorage.getItem('connections');
  // const invitee = localStorage.getItem('Invitee');

  useEffect(() => {
  
    const fetchData = async () => {
      try {
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
          setUser(currentUser);
          // setUserId(currentUser.uid);
          
          // console.log('Current User:', currentUser);
          // console.log(userID);

          if (currentUser) {
            // console.log('Calling backend');
            if (userID){
              const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/get-code?id=${userID}`);
              const data = await response.json();
              setConnections(data.connections);
              localStorage.setItem('connections', JSON.stringify(data.connections));
            }
            
          } else {
            console.error('User is not logged in');
            window.location.href = `/`;
          }
        });

        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching data from server', error);
        
      }
    };

    fetchData();

    // Cleanup the observer when the component is unmounted
    // return () => unsubscribe();
  }, []);

  return (
    // <div className="gradient-background-2">
    //   <h2 className="fade-in2">Congratulations! 🥳</h2>
    //   <p className="fade-in2">You have successfully connected your Socials</p>
    //   <br></br>
    //   <p className="fade-in2">
    //     You will be notified when we find a match for you via email/text
    //     message!👀
    //   </p>
    //   <br></br>
    //   {!profileStatus &&
    //   <button className="register-btn fade-in" onClick={() => window.location.href='/register'}>
    //     Complete Your Profile
    //   </button>
    //   }
    //   {!socialStatus &&
    //   <button className="register-btn fade-in" onClick={() => window.location.href='/connect'}>
    //     Connect Your Socials
    //   </button>
    //   }
    // </div>
      <div className='main-container' id="leader">
        <div className='board-container'>
          {user && <h2 id="leader">{user.displayName}'s Leaderboard</h2>}

          <div className='leader-board'>
          <div className='account-box' id="leader">
            {/* <div className='top-box'>
              <code className='box-name'>MY ACCOUNT</code>
              {user && user.user && <Button type="submit" color="primary" href="/edit-profile">Edit</Button>}
            </div> */}
                  {connections && connections.map((connection, index) =>
                    <div key={index} className='connection-container'>
                      <div className='name-style2'>{connection.invitee.name}</div>
                      <div className='score-style'>{connection.score}</div>
                    </div>
                  )}
              </div>
          </div>
        </div>
      </div>
  );
};

export default Leaderboard;
