// // frontend/client/src/components/ConnectYouTube.js
// import { useEffect, React} from 'react';

// const ConnectYouTube = () => {
//   const handleConnectYouTube = () => {
//     // Load the gapi client library
//     // window.gapi.load('client:auth2', () => {
//     //   window.gapi.client.init({
//     //     apiKey: '', // Replace with your API key
//     //     clientId: '193002709960-he3mt0bh9eq3819afm941ivt49i35r6o.apps.googleusercontent.com', // Replace with your client ID
//     //     scope: 'https://www.googleapis.com/auth/readonly',
//     //     discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest']
//     //   }).then(() => {
//     //     // Sign in the user upon button click
//     //     window.gapi.auth2.getAuthInstance().signIn();
//     //   });
//     // });
//     window.location.href = 'http://localhost:3000/connect-youtube';

//     console.log('Connecting to YouTube...');
//   };

//   useEffect(() => {
//     // Load the gapi script when the component mounts
//     const script = document.createElement('script');
//     script.src = 'https://apis.google.com/js/platform.js';
//     script.onload = () => {
//       window.gapi.load('client:auth2', () => {
//         window.gapi.client.init({
//           apiKey: '', // Replace with your API key
//           clientId: '193002709960-he3mt0bh9eq3819afm941ivt49i35r6o.apps.googleusercontent.com', // Replace with your client ID
//           scope: 'https://www.googleapis.com/auth/youtube.force-ssl',
//           discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest']
//         });
//       });
//     };
//     document.body.appendChild(script);
//   }, []);

//   return (
//     <div>
//       <h1>Connect Your YouTube Account</h1>
//       <p>Click the button below to connect your YouTube account.</p>
//       <button onClick={handleConnectYouTube}>Connect to YouTube</button>
//     </div>
//   );
// };

// export default ConnectYouTube;
// require('dotenv').config();

import React from 'react';
import {Link} from 'react-router-dom';
import '../styles/ConnectYouTube.css';

const ConnectYouTube = () => {
  console.log(process.env.REACT_APP_BASE_URL);
  console.log('ConnectYouTube is rendered')
  const handleConnectYouTube = () => {
    console.log("handleConnectYouTube is called");
    // Redirect the user to your server to initiate the OAuth 2.0 flow
    window.location.href = `${process.env.REACT_APP_BASE_URL}/api/connect-youtube`; // Replace with your server URL
    console.log(process.env.REACT_APP_BASE_URL);
    
  };

  return (
    <div className="gradient-background">
      <h1>Connect</h1>
      <p>Your Socials (1/2)</p>
      <button className="styled-button" onClick={handleConnectYouTube} >YouTube</button>
    </div>
  );
};

export default ConnectYouTube;
