// GenderForm.js
import React, {useState} from 'react';

function GenderForm({ formData, nextStep, handleChange }) {
  const gender = formData ? formData.gender : '';
  const [selectedGender, setSelectedGender] = useState(null);

  const handleGenderChange = (gender) => {
    setSelectedGender(gender);
    // console.log(gender);
    // handleChange('gender', gender);
    handleChange('gender', gender, nextStep);
    // nextStep();

    // setTimeout(nextStep, 50); 
  };

  return (
    <div>
      <h2 className="fade-in2">Select your Gender</h2>
      <div className="gender-container fade-in2">
        <button
          // className={selectedGender === 'male' ? 'button-selected' : 'button-unselected'}
          onClick={() => handleGenderChange('Male')}
          onKeyDown={(e) => {
            if (e.key === "Enter") nextStep();
          }}
        >👦<br></br>Male</button>

        <button
          // className={selectedGender === 'female' ? 'button-selected' : 'button-unselected'}
          onClick={() => handleGenderChange('Female')}
          onKeyDown={(e) => {
            if (e.key === "Enter") nextStep();
          }}
        >👧<br></br>Female</button>

        <button
          // className={selectedGender === 'non-binary' ? 'button-selected' : 'button-unselected'}
          onClick={() => handleGenderChange('Non-Binary')}
          onKeyDown={(e) => {
            if (e.key === "Enter") nextStep();
          }}
        >🧑<br></br>Non-binary</button>
      </div>
    </div>
  );
}

export default GenderForm;