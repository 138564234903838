// Connect.js
import React, { useState, useEffect } from "react";
import { auth } from "../config/firebase-config";

function Avivox({ formData, nextStep, handleChange }) {
  const [user, setUser] = useState(null);
  const [spotifyConnected, setSpotifyConnected] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const success = params.get('success') === 'true'; 

  // Use the success state to control the animation
  const [showPage, setShowPage] = useState(!success);

  useEffect(() => {

    // Set an observer on the Auth object to get the current user
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      //   console.log("Current User:", currentUser);
    //   if (!currentUser) {
    //     alert("Please log in to Flair to continue");
    //     window.location.href = `/`;
    //   } else {
    //     setSpotifyConnected(
    //       localStorage.getItem(`spotifyConnected_${currentUser.uid}`) === "true"
    //     );
    //     setYoutubeConnected(
    //       localStorage.getItem(`youtubeConnected_${currentUser.uid}`) === "true"
    //     );
    //   }
    //   if (currentUser && spotifyConnected && youtubeConnected) {
    //     window.location.href = `/success`;
    //   }
    });

    // Cleanup the observer when the component is unmounted
    return () => unsubscribe();
  }, []);

  const connectTwitter = () => {
    console.log("connectTwitter is called");
    window.location.href = `${process.env.REACT_APP_BASE_URL}/connect-twitter`;
  }; 

  return (
    <div className="gradient-background-7">
      <h2>Connect with Avivox</h2>
      <div className="gender-container" id="social">
        <button
          className={spotifyConnected ? "button-disabled" : ""}
          onClick={connectTwitter}
          //   disabled={spotifyConnected}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="80px" padding="10px">
            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
          Twitter/X
        </button>


      </div>
      <div className="centered-text">
        <div className="centered-text">
            <h3>If you have any questions, please reach out to us directly through:</h3>
            <h3><a href="https://x.com/avivoxapp" style={{textDecoration: 'underline'}}>Twitter/X</a> or <a href="mailto:avivoxapp@gmail.com" style={{textDecoration: 'underline'}}>Email</a></h3>
        </div>
        <div className="message">
          {(success) && (
              <p>Success!</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Avivox;
