import React from 'react';
import "../styles/Misc.css";
import Background from '../components/about-waves.svg'

const PrivacyPolicy = () => {
  return (
    <div style={{backgroundImage:`url(${Background})`, backgroundAttachment: 'fixed'}}>
    <div className="privacy-policy fade-in">
      <h1>Privacy Policy</h1>

      <section>
        <p style={{fontSize:"20px", fontWeight:"400"}}>
          <b>Flair</b> is a platform designed to enhance social connections through
          shared interests and activities. This Privacy Policy explains how
          Flair accesses, uses, stores, and shares user data, particularly data
          obtained through Google sign-in. By using Flair, you agree to the
          terms outlined in this policy.
        </p>
      </section>

      {/* Add more sections and details as necessary */}

      <section>
        <h2>Information Collected</h2>
        <p>Flair collects and processes the following information:</p>
        <ol>
          <li>
            <strong>Google User Data:</strong> When you sign in with Google,
            Flair collects basic user information such as your name, email, and
            profile picture. This data is used to create and personalize your
            Flair account.
          </li>

          <li>
            <strong>Authentication Tokens:</strong> Flair may obtain and store
            authentication tokens to facilitate secure sign-ins and access to
            Google services.
          </li>

          <li>
            <strong>YouTube User Data:</strong> When you link your YouTube
            account, Flair collects basic user information such as your name,
            email, and profile picture, as well as additional data from your
            YouTube account. This includes your subscriptions, liked videos, and
            access tokens. The collected YouTube user data enhances your Flair
            experience by providing personalized matches based on your
            preferences.
          </li>

          <li>
            <strong>Spotify User Data:</strong> When you link your Spotify
            account, Flair collects basic user information such as your name,
            email, and profile picture, as well as additional data from your
            Spotify account. This includes your playlists, liked songs, and
            access tokens. The collected Spotify user data enhances your Flair
            experience by providing personalized matches based on your
            preferences. Flair does not use Spotify Data to train AI or machine learning models.
          </li>
        </ol>
      </section>

      <section>
        <h2>Use of Information</h2>
        <p>The collected data is used for the following purposes:</p>
        <ol>
          <li>
            <strong>Profile Creation:</strong> Your name, email, and profile
            picture are used to create and customize your Flair profile.
          </li>

          <li>
            <strong>Personalized Matches:</strong> Your YouTube user data is
            used to match you with other users who share your interests and
            activities.
          </li>

          <li>
            <strong>Communication:</strong> Your email is used to send you
            important updates and notifications regarding your Flair account.
          </li>

          <li>
            <strong>Authentication:</strong> Authentication tokens are utilized
            to ensure secure sign-ins and interactions with Google services
          </li>
        </ol>
      </section>

      {/* Other sections */}

      <h2>Data Storage and Security</h2>
      <p>
        Flair takes measures to securely store and protect user data. This
        includes encryption protocols and access controls to prevent
        unauthorized access.
      </p>

      <h2>Data Sharing</h2>
      <p>
        Flair does not share Google user data with third parties. User
        information is solely used for internal purposes to enhance the Flair
        experience.
      </p>

      <h2>Limited Use Requirements</h2>
      <p>
        Flair adheres to Google's Limited Use Requirements. User data is used
        only for the disclosed purposes and in compliance with Google's
        policies.
      </p>

      <h2>Privacy Policy Updates</h2>
      <p>
        This Privacy Policy may be updated to reflect changes in our practices.
        Users will be notified of any significant updates.
      </p>

      <p>
        By using Flair, you acknowledge and consent to the practices described
        in this Privacy Policy. If you have any questions or concerns, please 
        {" "}<a href="mailto:letsflair.inc@gmail.com" target="_blank"
          rel="noopener noreferrer">contact us</a>.
      </p>

      <p>
        Flair's use and transfer to any other app of information received from
        Google APIs will adhere to{" "}
        <a
          href="https://developers.google.com/terms/api-services-user-data-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google API Services User Data Policy
        </a>
        , including the Limited Use requirements.
      </p>
      {/* <p>
        <a href="/privacy">Privacy Policy</a>
      </p> */}
      <p style={{ fontSize: "10px", paddingBottom: "10px", paddingTop:"10px", fontStyle: "italic" }}>
        (Last Updated: 03/25/2024)
      </p>
    </div>
    </div>
  );
};

export default PrivacyPolicy;